.sub-btn.add-button {
  position: fixed !important;
  right: 0;
  bottom: 8vh;
  transform: rotate(270deg) translateY(100%);
  font-weight: normal;
  letter-spacing: 1.3px;
  border-radius: 8px 8px 0 0 !important;
  font-size: 16px;
  z-index: 1111;
}

@media (max-width: 601px) {
  .sub-btn.add-button {
    width: auto;
  }
}
